export default [
  {
    title: 'ダッシュボード',
    icon: { icon: 'tabler-smart-home' },
    to: 'admins-dashboard',
    action: 'manage',
    subject: 'admin',
    badgeClass: 'bg-light-primary text-primary',
  },
  {
    title: 'イチオシ',
    icon: { icon: 'material-symbols-campaign' },
    to: 'admins-pickup',
    action: 'manage',
    subject: 'admin',
  },
  {
    title: '施設を探す',
    icon: { icon: 'tabler-list-search' },
    to: 'admins-search',
    action: 'manage',
    subject: 'admin',
  },
  {
    title: 'お気に入り',
    icon: { icon: 'material-symbols-bookmark-add' },
    to: 'admins-bookmark',
    action: 'manage',
    subject: 'admin',
  },
  {
    title: '申請情報',
    icon: { icon: 'material-symbols-order-approve-outline' },
    action: 'manage',
    subject: 'admin',
    children: [
      {
        title: '進捗一覧',
        to: 'admins-entry',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '書類管理',
        to: 'admins-document',
        action: 'manage',
        subject: 'admin',
      },
    ],
  },
  {
    title: 'お問い合わせ',
    icon: { icon: 'material-symbols-chat-bubble-outline' },
    action: 'manage',
    subject: 'admin',
    children: [
      {
        title: '催事業者',
        to: 'admins-chat-client',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '施設',
        to: 'admins-chat-owner',
        action: 'manage',
        subject: 'admin',
      },
    ],
  },
  {
    title: 'お知らせ',
    icon: { icon: 'material-symbols-newspaper' },
    to: 'admins-news',
    action: 'manage',
    subject: 'admin',
  },
  {
    title: '設定',
    icon: { icon: 'tabler-settings' },
    action: 'manage',
    subject: 'admin',
    children: [
      {
        title: '施設情報',
        to: 'admins-setting-facility',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '人気順位',
        to: 'admins-setting-facility-rank',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '催事スペース',
        to: 'admins-setting-place',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '施設カレンダーメモ設定',
        to: 'admins-setting-place-comment',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '施設カレンダーNG設定',
        to: 'admins-setting-place-disable',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '手数料設定',
        to: 'admins-setting-commission',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '手数料パターン設定',
        to: 'admins-setting-commission-type',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '催事業者企業',
        to: 'admins-setting-client',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '催事業者アカウント',
        to: 'admins-setting-store',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '管理者アカウント',
        to: 'admins-setting-admin',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '全アカウント',
        to: 'admins-setting-user',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '施設管理者',
        to: 'admins-setting-owner',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '商材',
        to: 'admins-setting-item',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '設定カテゴリ',
        to: 'admins-setting-type',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: 'ヘルプ',
        to: 'admins-setting-help',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: '設定API',
        to: 'admins-setting-api',
        action: 'manage',
        subject: 'admin',
      },
    ],
  },
  {
    title: 'トラックログ',
    icon: { icon: 'material-symbols-history' },
    action: 'manage',
    subject: 'admin',
    children: [
      {
        title: 'システムログ',
        to: 'admins-history',
        action: 'manage',
        subject: 'admin',
      },
      {
        title: 'ユーザーログイン',
        to: 'admins-history-login',
        action: 'manage',
        subject: 'admin',
      },
    ],
  },
  {
    title: 'SC事業部-KPI',
    icon: { icon: 'tabler-file-report' },
    to: 'admins-report',
    action: 'manage',
    subject: 'admin',
  },
  {
    title: '場所とる.comとは',
    icon: { icon: 'tabler-home' },
    to: 'admins-dashboard',
    action: 'manage',
    subject: 'common',
    badgeClass: 'bg-light-primary text-primary',
  },
  {
    title: 'お問い合わせ',
    icon: { icon: 'tabler-email' },
    to: 'contact',
    action: 'manage',
    subject: 'common',
    badgeClass: 'bg-light-primary text-primary',
  },
]
